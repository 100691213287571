import defaultConfig from './defaultConfig'

export default class Service {
  axiosIns = null

  serviceConfig = { ...defaultConfig }

  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
      this.axiosIns = axiosIns
      this.serviceConfig = { ...this.serviceConfig, ...jwtOverrideConfig }
  
      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => response,
        error => {
          // const { config, response: { status } } = error
          const { config, response } = error
          const originalRequest = config
  
          // if (status === 400) {
          if (response && response.status === 400) {
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                resolve(this.axiosIns(originalRequest))
              })
            })
            return retryOriginalRequest
          }
          return Promise.reject(error)
        },
      )
  }

  fetchDataCustomers() {
    return this.axiosIns.get(`/api/v1/customers`)
  }

  fetchDataInstansi(users_id) {
    return this.axiosIns.get(`/api/v1/customers/?users_id=${users_id}`)
  }

  fetchDataInstansiById(id_instansi) {
    return this.axiosIns.get(`/api/v1/customer/${id_instansi}`)
  }

  createInstansi(...args) {
    return this.axiosIns.post(this.serviceConfig.createInstansi, ...args)
  }

  deleteInstansi({id, deleted_at}) {
    return this.axiosIns.delete(`/api/v1/customer/${id}`)
  }

  createAccount(...args) {
    return this.axiosIns.post(this.serviceConfig.createAccount, ...args)
  }

  createAccountwarehouse(...args) {
    return this.axiosIns.post(this.serviceConfig.createAccountwarehouse, ...args)
  }

  fetchDataAccount() {
    return this.axiosIns.get(this.serviceConfig.fetchDataAccounts)
  }

  fetchDataAccountSearch({ id_instansi, role }) {
    return this.axiosIns.get(`/api/v1/accounts/search?id_instansi=${id_instansi}`)
  }

  fetchDataAccountByIdSearch({ id }) {
    return this.axiosIns.get(`/api/v1/accounts/search?id=${id}`)
  }

  updatedAccount(args) {
    return this.axiosIns.post(`/api/v1/account/update?id=${args.id}`, {
      name: args.name,
      email: args.email,
      active: args.status,
      updated_at: args.updated_at
    })
  }

  deletedAccount(args) {
    return this.axiosIns.post(`/api/v1/account/delete?id=${args.id}`, {
      deleted_at: args.deleted_at
    })
  }

  fetchDataUsersRoles() {
      return this.axiosIns.get('/api/v1/user/roles')
  }

  createWarehouse(...args) {
    return this.axiosIns.post(this.serviceConfig.createWarehouse, ...args)
  }

  deleteWarehouse({id, deleted_at}) {
    return this.axiosIns.delete(`/api/v1/warehouse/${id}`)
  }

  fetchDataWarehouse(id_instansi) {
    return this.axiosIns.get(`/api/v1/warehouse/instansi/${id_instansi}`)
  }

  createProduct(args) {
    return this.axiosIns.post(this.serviceConfig.createProduct, args, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  updateProduct(args) {
    return this.axiosIns.put(`/api/v1/product/update/?id=${args.get('id')}`, args, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  settleProduct(...args) {
    return this.axiosIns.post(this.serviceConfig.settleProduct, ...args)
  }

  createDistributionImages(args) {
    return this.axiosIns.post('/api/v1/product/images', args, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  fetchImages(distributionId) {
    return this.axiosIns.get(`/api/v1/products/listImages/?distribution_id=${distributionId}`)
  }

  deletedDistributionImages(id) {
    return this.axiosIns.delete(`/api/v1/product/imagesDelete/?id=${id}`)
  }

  createDataProducs(...args) {
    return this.axiosIns.post(this.serviceConfig.createProductArray, ...args)
  }

  transactionsInsert(...args) {
    return this.axiosIns.post('/api/v1/transactions', ...args)
  }

  receiptProduct(...args) {
    return this.axiosIns.post(this.serviceConfig.receiptProductArray, ...args)
  }

  updateReceipt(...args) {
    return this.axiosIns.put('/api/v1/receipts', ...args)
  }

  fetchDataListReceipt(id_product, warehouse_id) {
    return this.axiosIns.get(`/api/v1/product/receipt/${id_product}/warehouse/${warehouse_id}`)
  }

  createBatchReceipt(...args) {
    return this.axiosIns.post(`/api/v1/product/receipts`, ...args)
  }

  deletedSupplying(id) {
    return this.axiosIns.delete(`/api/v1/receipt/${id}`)
  }

  deletedProduct(id) {
    return this.axiosIns.delete(`/api/v1/product/?id=${id}`)
  }

  fetchDataProducsById(id) {
    return this.axiosIns.get(`/api/v1/product/${id}`)
  }

  fetchDataProduct(id_instansi) {
    return this.axiosIns.get(this.serviceConfig.fetchDataProduct)
  }

  fetchDataListProducs(id) {
    return this.axiosIns.get(`/api/v1/product/instansi/${id}`)
  }

  fetchDataDetailProducts({ product_id, instansi_id }) {
    return this.axiosIns.get(`/api/v1/transactions?product_id=${product_id}&instansi_id=${instansi_id}`)
  }

  fetchDataDetailQuantity(product_id, warehouse_id) {
    return this.axiosIns.get(`/api/v1/transactions/distribution?id_product=${product_id}&warehouse_id=${warehouse_id}`)
  }

  fetchDataListSupplying(id_instansi) {
    return this.axiosIns.get(`/api/v1/product/supplying/${id_instansi}`)
  }

  fetchDataSupplyById(id) {
    return this.axiosIns.get(`/api/v1/supplying/${id}`)
  }

  fetchDataCode(id) {
    return this.axiosIns.get(`/api/v1/product/${id}`)
  }

// Categories
  fetchDataCategories(idInstansi) {
    return this.axiosIns.get(`/api/v1/allCategories/${idInstansi}`)
  }

  createCategories(...args) {
    return this.axiosIns.post(this.serviceConfig.createCategories, ...args)
  }

  fetchCategoriesById(id) {
    return this.axiosIns.get(`/api/v1/categories/${id}`)
  }

  updateCategories( args) {
    const { id } = args
    return this.axiosIns.put(`/api/v1/categories/${id}`, args)
  }

  deletedCategories(id) {
    return this.axiosIns.delete(`/api/v1/categories/${id}`)
  }

// Responsible
  fetchDataListResponsible(id) {
    return this.axiosIns.get(`/api/v1/responsible?warehouse_id=${id}`)
  }

  createDistribution(...args) {
    return this.axiosIns.post(`/api/v1/distribution`, ...args)
  }

  updateDistribution(args) {
    return this.axiosIns.put(`/api/v1/distribution?id=${args.id}`, {
      quantity: args.quantity,
      unit: args.unit,
      series: args.unique,
      title: args.title,
      responsible_name: args.responsible_name,
      pic: args.pic,
      control_date: args.control_date,
      description: args.description,
      id_product: args.id_product,
      warehouse_id: args.warehouse_id
    })
  }

  updateDistributionLabel(args) {
    return this.axiosIns.put(`/api/v1/distribution/update/?id=${args.distribution_id}`, {
      label_status: args.label_status,
      label_qr_code: args.label_qr_code,
    })
  }

  deletedDistribution({id, deleted_at}) {
    return this.axiosIns.put(`/api/v1/distribution?id=${id}`, {
      deleted_at: deleted_at
    })
  }

  fetchObjDistribution({id}) {
    return this.axiosIns.get(`/api/v1/distribution?distribution.id=${id}`)
  }

  fetchDistributionByProductId({id_product}) {
    return this.axiosIns.get(`/api/v1/distribution?distribution.id_product=${id_product}`)
  }

  fetchDataListDistribution({warehouse, product}) {
    return this.axiosIns.get(`/api/v1/distribution/joinDistribution/?distribution.warehouse_id=${warehouse}&distribution.id_product=${product}`)
  }

  createComposition(...args) {
    return this.axiosIns.post(`/api/v1/composition`, ...args)
  }

  updateComposition(...args) {
    return this.axiosIns.put(`/api/v1/composition`, ...args)
  }

  fetchDataListComposition(id) {
    return this.axiosIns.get(`/api/v1/composition/${id}`)
  }

  fetchDataCompositionById(id) {
    return this.axiosIns.get(`/api/v1/compositionById/${id}`)
  }

  deletedComposition(id) {
    return this.axiosIns.delete(`/api/v1/composition/${id}`)
  }

  fetchDataCompositionWarehouse(id_product, warehouse_id) {
    return this.axiosIns.get(`/api/v1/composition/${id_product}/warehouse/${warehouse_id}`)
  }

  // Group Units
  fetchDataListGroupUnitsByInstansi(instansi_id) {
    return this.axiosIns.get(`/api/v1/groupsUnits/${instansi_id}`)
  }

  fetchDataListGroupUnits() {
    return this.axiosIns.get(`/api/v1/groupsUnits`)
  }

  fetchDataGroupUnit(id) {
    return this.axiosIns.get(`/api/v1/groupsUnit/${id}`)
  }

  createGroupUnit(...args) {
    return this.axiosIns.post(`/api/v1/groupsUnit`, ...args)
  }

  updateGroupUnit(...args) {
    return this.axiosIns.put(`/api/v1/groupsUnit`, ...args)
  }

  deletedGroupUnit(id) {
    return this.axiosIns.delete(`/api/v1/groupsUnits/${id}`)
  }

  // Unit
  fetchDataListUnit(id) {
    return this.axiosIns.get(`/api/v1/units/${id}`)
  }
  
  fetchDataListUnitsByGroup(group_id) {
    return this.axiosIns.get(`/api/v1/unitsByGroup/${group_id}`)
  }

  fetchDataUnit(product_id) {
    return this.axiosIns.get(`/api/v1/units/${product_id}`)
  }

  createUnits(...args) {
    return this.axiosIns.post(`/api/v1/units`, ...args)
  }

  updateUnits(...args) {
    return this.axiosIns.put(`/api/v1/units`, ...args)
  }

  deleteUnits(id) {
    return this.axiosIns.delete(`/api/v1/units/${id}`)
  }

  createTask(...args) {
    return this.axiosIns.post(`/api/v1/transactions/calibration`, ...args)
  }

  checkProductCalibration(...args) {
    return this.axiosIns.post(`/api/v1/transactions/checkProduct`, ...args)
  }

  fetchDataListTask() {
    return this.axiosIns.get(`/api/v1/transactions/task`)
  }

  fetchDataDetailCalibration(task_id) {
    return this.axiosIns.get(`/api/v1/transactions/calibrationByIdTask?task_id=${task_id}`)
  }

  createRooms(...args) {
    return this.axiosIns.post(`/api/v1/responsible`, ...args)
  }

  fetchDataPrintLabel(...args) {
    return this.axiosIns.post(`/api/v1/product/distribution`, ...args)
  }

  createDepreciation(...args) {
    return this.axiosIns.post(`/api/v1/depreciation/createDepreciation`, ...args)
  }

  updateDepreciation(args) {
    return this.axiosIns.post(`/api/v1/depreciation/updateDepreciation/?id=${args.id}`, {
      received_date: args.received_date,
      price: args.price,
      residu: args.residu,
      economic_age: args.economic,
      custodian: args.custodian,
    })
  }

  fetchDataDepreciation(distributionId) {
    return this.axiosIns.get(`/api/v1/depreciation/getDepreciationByDistribution/?distribution_id=${distributionId}`)
  }

  fetchDataLabel() {
    return this.axiosIns.get(`/api/v1/label/getAll`)
  }

  fetchDataLabelSearch(warehouse_id) {
    return this.axiosIns.get(`/api/v1/label/search/?warehouse_id=${warehouse_id}`)
  }

  createLabel(args) {
    return this.axiosIns.post(`/api/v1/label/create`, args, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  updateLabel(args) {
    return this.axiosIns.put(`/api/v1/label/update`, args, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  deletedLabel(id) {
    return this.axiosIns.delete(`/api/v1/label/delete/?id=${id}`)
  }

  fetchDataDistribution(idInstansi) {
    return this.axiosIns.get(`/api/v1/distribution/search/?product.id_instansi=${idInstansi}`)
  }

  fetchDataDistributionByLabel(idInstansi) {
    return this.axiosIns.get(`/api/v1/distribution/search/?product.id_instansi=${idInstansi}&distribution.label_status=0`)
  }

  fetchDataDistributionByWarehouse(warehouse_id) {
    return this.axiosIns.get(`/api/v1/distribution/search/?distribution.warehouse_id=${warehouse_id}`)
  }

  fetchDataDistributionByLabelQr(label_qr_code) {
    return this.axiosIns.get(`/api/v1/distribution/search/?distribution.label_qr_code=${label_qr_code}`)
  }

  fetchDataDistributionByWarehouseAndStatus(warehouse_id) {
    return this.axiosIns.get(`/api/v1/distribution/search/?distribution.warehouse_id=${warehouse_id}&distribution.label_status=0`)
  }

  insertProductObject(...args) {
    return this.axiosIns.post('/api/v1/product/productObject', ...args)
  }

  insertDistributionObject(...args) {
    return this.axiosIns.post('/api/v1/distribution/insertDistributionObject', ...args)
  }

  BatchDistribution(...args) {
    return this.axiosIns.post('/api/v1/distribution/upload', ...args)
    // return this.axiosIns.post('/api/v1/distribution/upload', args, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // })
  }

  createTask(...args) {
    return this.axiosIns.post(`/api/v1/task/create`, ...args)
  }

  fetchDataTask(distribution_id) {
    return this.axiosIns.get(`/api/v1/task/search/?task.distribution_id=${distribution_id}`)
  }

}